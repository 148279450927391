/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentItemProperties } from '@/data/types/Slot';

export const getChildItem = (
  content: ContentItemProperties[],
  emsName: string

) => {
  const contentItem = content.find((contentItem: ContentItemProperties) => contentItem.emsName === emsName)
  return {
    contentItem
  };
};
