/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentItemProperties } from '@/data/types/Slot';
import {
	FOOTER_COMPANY_LINKS,
	FOOTER_EMAIL_COPY,
	FOOTER_LOGO,
	FOOTER_POLICY,
	FOOTER_SOCIAL_LINKS,
	HEADER_COPY,
	FOOTER_BRAND_STATEMENT,
} from '@/data/constants/marketing';

const contentItems: ContentItemProperties[] = [
	{ emsName: FOOTER_COMPANY_LINKS },
	{ emsName: FOOTER_EMAIL_COPY },
	{ emsName: FOOTER_LOGO },
	{ emsName: FOOTER_POLICY },
	{ emsName: FOOTER_SOCIAL_LINKS },
	{ emsName: HEADER_COPY },
	{ emsName: FOOTER_BRAND_STATEMENT },
];
export default contentItems;
