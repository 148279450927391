/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const footerContainerSX = (csrSession: boolean): SxProps<Theme> => ({
	borderRadius: '0',
	boxShadow: 0,
	alignSelf: 'stretch',
	justifySelf: 'flex-end',
	backgroundColor: 'background.paper',
	color: 'background.disabled',
	padding: 0,
	marginBottom: 5,
	h6: {
		fontSize: { md: '14px', xs: '18px' },
		fontWeight: '600',
	},
	fieldset: {
		borderRight: 'none',
		borderWidth: '1px',
	},

	...(!csrSession && { mt: 'auto' }),
});
