import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { FOOTER_COPYRIGHT } from '@/data/constants/marketing';
import { Container, Stack } from '@mui/material';
import { globalEspotContainerSX } from '@/components/content/EMarketingSpot/styles/globalEspotContainer';
import { renderContent } from '@/utils/renderContent';
import { FC } from 'react';

export const Copyright:FC <{isMobile?: boolean}> = ({isMobile}) => {
	const { data: espotData } = useContentRecommendation(FOOTER_COPYRIGHT);
	return (
		<Container maxWidth='xl' sx={{ padding: { md: 0 } }}>
			{espotData && Array.isArray(espotData) ? (
				<Stack component="div" alignItems={isMobile ? 'center': 'unset'} sx={globalEspotContainerSX}>
					{espotData?.map((content) => renderContent(content))}
				</Stack>
			) : null}
		</Container>
	);
};
