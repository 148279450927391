import { SxProps, Theme } from '@mui/material';
import { palette } from '@/styles/Ruby/palette';

export const footerNavSX: SxProps<Theme> = (theme) => ({
	maxWidth: { md: 1280, xs: 'none' },
	margin: { xs: 0 },
	display: { md: 'flex', xs: 'block' },
	justifyContent: { md: 'space-between' },
	'.MuiAccordion-root': {
		boxShadow: 'none',
		borderBottom: { md: 'none', xs: `1px solid` },
		borderColor: 'border.gray',
	},
	'.MuiCollapse-root': {
		overflow: 'visible',
	},
	'.MuiAccordionSummary-content': {
		justifyContent: 'space-between',
	},
	'.MuiAccordionSummary-root': {
		cursor: { md: 'none', xs: 'auto' },
		paddingLeft: { md: 0, xs: 2 },
		paddingRight: { md: 0, xs: 2 },
		borderBottom: 0,
		borderTop: { md: 'none', xs: `1px solid` },
		borderColor: 'border.gray',
		justifyContent: { md: 'none', xs: 'space-between' },
		paddingTop: { xs: 1, md: 0 },
		paddingBottom: { xs: 1, md: '6px' },
		'.Mui-expanded': {
			'.expand-less': {
				display: 'block',
			},
			'.expand-more': {
				display: 'none',
			},
		},
	},
	'.MuiAccordionDetails-root': {
		paddingLeft: { md: 0, xs: 2 },
		paddingRight: { md: 0, xs: 2 },
		paddingTop: { md: 0, xs: 0 },
		borderBottom: 0,
	},

	i: {
		color: 'icons.fillcolor',
		fontSize: '36px',
	},
	li: {
		listStyleType: 'none',
		a: {
			fontSize: '12px',
			fontWeight: '400',
			color: 'text.main',
		},
	},
});
