/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentItemProperties } from '@/data/types/Slot';
import {
	HEADER_LOGO,
	HEADER_COPY,
	HEADER_ACCOUNT_LINKS,
	HEADER_TOP_NAV,
	HEADER_TOP_NAV_DROPDOWN,
	HEADER_MOBILE_NAV,
	HEADER_GLOBAL_BANNER
} from '@/data/constants/marketing';

const contentItems: ContentItemProperties[] = [
	{ emsName: HEADER_LOGO },
	{ emsName: HEADER_COPY },
	{ emsName: HEADER_TOP_NAV },
	{ emsName: HEADER_TOP_NAV_DROPDOWN },
	{ emsName: HEADER_ACCOUNT_LINKS },
	{ emsName: HEADER_MOBILE_NAV },
	{ emsName: HEADER_GLOBAL_BANNER },
];
export default contentItems;
