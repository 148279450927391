import { SxProps, Theme } from '@mui/material';

export const emailSignUpSX: SxProps = {
	paddingLeft: { md: 0, xs: '16px' },
	paddingRight: { md: 0, xs: '16px' },
	'.MuiTextField-root': {
		width: '100%',
	},
	'.MuiOutlinedInput-root': {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},

	h6: {
		fontSize: { md: '14px', xs: '18px' },
		fontWeight: '600',
	},
	'.MuiTypography-p': {
		fontSize: '12px',
		padding: '10px 0 5px 0'
	},
	'.MuiInputBase-input': {
		fontSize: '16px',
    	padding: '13.5px 12px'
	},
};
