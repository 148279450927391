import { Box, TextField, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useState } from 'react';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { EMAIL_SUBSCRIPTION_URL } from '@/data/constants/externalURL';

export const EmailSubscriptionField = () => {
	const [email, setEmail] = useState<string>('');
	const [error, setError] = useState<boolean>(false);
	const router = useNextRouter();
	const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
	const handleSubmit = () => {
		if (emailRegex.test(email)) {
			router.push(
				{
					pathname: EMAIL_SUBSCRIPTION_URL,
					query: { email },
				}
			);
			setError(false);
		} else {
			setError(true);
		}
	};
	return (
		<Box sx={{ display: 'flex', mt: 1 }}>
			<TextField
				required
				type="email"
				fullWidth
				id="email"
				name="email"
				autoComplete="email"
				placeholder="Enter Email Address"
				error={error}
				helperText={error ? 'Please enter a valid email' : null}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				sx={{ borderTopRightRadius: '0', borderBottomRightRadius: 0 }}
			/>
			<Box>
                <Button
                    onClick={handleSubmit}
                    type="submit"
                    aria-label="submit"
                    variant="blue-contained"
                    sx={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', py: '.75rem', '& .MuiButton-endIcon': { ml: 0 } }}
                    endIcon={<ChevronRightIcon sx={{ fontSize: '1.5rem !important' }} />}
                >
                </Button>
            </Box>
		</Box>
	);
};
